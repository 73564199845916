* {
  box-sizing: border-box;
}

html {
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  line-height: 1.4;
  color: #5b5b5d;
  background: #f5f5f5;
}

input, textarea {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #000;
}

.hidden {
  display: none !important;
}

.layout {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.layout_scroll_blocked {
  overflow: hidden;
}

.container {
  position: relative;
  flex: 1 1 0;
  width: 100%;
  margin: 0 auto;
  padding: 0;
}
.container_type_limited {
  width: 290px;
}
.container_type_tough {
  flex: none;
}
.container_type_centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.container_space_regular {
  padding: 32px 0 48px;
}
.container_space_regular + .container_space_regular {
  padding-top: 0;
}
.container_space_section {
  padding: 64px 0 96px;
}
.container_space_narrow {
  padding: 24px 0;
}
.container__heading {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 16px;
}
@media (min-width: 375px) {
  .container_type_limited {
    width: 320px;
  }
}
@media (min-width: 425px) {
  .container_type_limited {
    width: 390px;
  }
}
@media (min-width: 768px) {
  .container_type_limited {
    width: 736px;
  }
  .container_type_sticky {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
  }
}
@media (min-width: 1200px) {
  .container_type_limited {
    width: 1096px;
  }
}

.section_type_main {
  flex-basis: 485px;
  flex-shrink: 0;
  padding-bottom: 64px;
}
.section__heading {
  font-size: 32px;
  font-weight: 400;
  text-align: left;
  margin: 0;
}
.section__subheading {
  font-size: 24px;
  text-align: center;
  color: #9c9c9c;
}
.section__heading + .section__subheading {
  margin-top: 24px;
}
.section__heading + .section__content, .section__content + .section__content {
  margin-top: 32px;
}
.section__subheading + .section__content, .section__subheading + .section__text {
  margin-top: 16px;
}
.section__content + .section__subheading {
  margin-top: 48px;
}
.section__heading-highlighter {
  position: relative;
  display: inline-block;
}
.section__text {
  margin: 32px auto;
  font-size: 18px;
  text-align: center;
}
.section__text_type_sparse {
  line-height: 36px;
}
.section__text_type_tight {
  line-height: 28px;
}
.section__text_align_left {
  text-align: left;
}
.section__text_size_medium {
  font-size: 16px;
}
.section__text:last-child {
  margin-bottom: 0;
}
.section__nav {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: 16px 0 32px;
}
.section__nav > a.current {
  font-weight: bold;
}
@media (min-width: 768px) {
  .section_type_main {
    padding: 140px 0 96px;
    flex-basis: 540px;
  }
  .section__heading {
    text-align: center;
  }
  .section__heading_size_large {
    font-size: 40px;
    font-weight: 300;
  }
  .section__nav {
    justify-content: center;
  }
}
@media (min-width: 1200px) {
  .section__text {
    max-width: 800px;
  }
  .section__text_width_full {
    max-width: 100%;
  }
}

.section_theme_default {
  background-color: #f5f5f5;
}
.section_theme_semilight {
  background-color: #fafafa;
}
.section_theme_light {
  background-color: #fff;
}

.section_special_index-search {
  z-index: 1;
  padding-top: 120px;
  padding-bottom: 110px;
}
.section_special_index-search .section__image {
  bottom: 0;
  width: 280px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.section_special_index-search .section__heading {
  text-align: center;
}
.section_special_index-search .section__subheading {
  font-size: 16px;
  font-weight: normal;
  margin-top: 12px;
  margin-bottom: 24px;
}
.section_special_index-search .search-bar {
  min-height: 252px;
}
@media (min-width: 320px) {
  .section_special_index-search {
    padding-bottom: 130px;
  }
  .section_special_index-search .section__image {
    width: 320px;
  }
}
@media (min-width: 768px) {
  .section_special_index-search {
    padding-bottom: 210px;
  }
  .section_special_index-search .search-bar {
    min-height: 175px;
  }
  .section_special_index-search .section__image {
    width: 480px;
  }
  .section_special_index-search .section__heading {
    font-size: 32px;
  }
}
@media (min-width: 1024px) {
  .section_special_index-search {
    padding-bottom: 260px;
  }
  .section_special_index-search .section__image {
    width: 650px;
  }
}

.section_special_index-search .section__heading {
  font-size: 22px;
}
.section_special_index-search .section__image {
  width: 100%;
  height: 130px;
  overflow: hidden;
}
.section_special_index-search .section__image > svg {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 700px;
}
@media (min-width: 425px) {
  .section_special_index-search .section__heading {
    font-size: 28px;
  }
  .section_special_index-search .section__image {
    height: 165px;
  }
  .section_special_index-search .section__image > svg {
    width: 900px;
  }
}
@media (min-width: 768px) {
  .section_special_index-search .section__image {
    height: 202px;
  }
  .section_special_index-search .section__image > svg {
    width: 1100px;
  }
}
@media (min-width: 1024px) {
  .section_special_index-search .section__heading {
    font-size: 32px;
  }
  .section_special_index-search .section__image {
    height: 258px;
  }
  .section_special_index-search .section__image > svg {
    width: 1400px;
  }
}

.section_special_index-areas .splitter:nth-of-type(2),
.section_special_index-areas .splitter:nth-of-type(4),
.section_special_index-areas .splitter:nth-of-type(7) {
  font-size: 0;
}
@media (min-width: 768px) {
  .section_special_index-areas .splitter:nth-of-type(n) {
    font-size: inherit;
  }
  .section_special_index-areas .splitter:nth-of-type(6) {
    display: none;
  }
}
@media (min-width: 1200px) {
  .section_special_index-areas .splitter:nth-of-type(n) {
    display: inline-block;
  }
}

.autocomplete {
  position: relative;
  text-align: left;
}
.autocomplete__input {
  width: 100%;
  height: 48px;
  outline: none;
  border: 1px solid #c3c3c4;
  border-radius: 24px;
  padding-left: 24px;
  padding-right: 48px;
  background-color: #fff;
  transition: background-color 0.3s ease;
}
.autocomplete__input:focus {
  border-color: #5b5b5d;
}
.autocomplete__input_type_placeholder {
  background-color: #e6e6e6;
}
.autocomplete__input_size_small > input {
  height: 40px;
  padding: 0 12px;
  font-size: 16px;
}
.autocomplete__results {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 100;
  background: #fff;
  border-radius: 2px;
  box-sizing: border-box;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
.autocomplete__result {
  position: relative;
  padding: 12px;
  cursor: pointer;
  font-size: 16px;
}
.autocomplete__result:hover {
  background: #e6e6e6;
}
.autocomplete__result_type_history, .autocomplete__result_type_current {
  padding-left: 40px;
}
.autocomplete__result_type_history:before, .autocomplete__result_type_current:before {
  display: inline-block;
  content: "";
  position: absolute;
  top: 20px;
  left: 12px;
  width: 18px;
  height: 18px;
  background-size: cover;
}
.autocomplete__result_type_no {
  padding-left: 32px;
}
.autocomplete__result_type_current:before {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="%235B5B5D"><path d="M500 232h-29.334C459.597 131.885 380.115 52.403 280 41.334V12c0-6.627-5.373-12-12-12h-24c-6.627 0-12 5.373-12 12v29.334C131.885 52.403 52.403 131.885 41.334 232H12c-6.627 0-12 5.373-12 12v24c0 6.627 5.373 12 12 12h29.334C52.403 380.115 131.885 459.597 232 470.666V500c0 6.627 5.373 12 12 12h24c6.627 0 12-5.373 12-12v-29.334C380.115 459.597 459.597 380.115 470.666 280H500c6.627 0 12-5.373 12-12v-24c0-6.627-5.373-12-12-12zM280 422.301V380c0-6.627-5.373-12-12-12h-24c-6.627 0-12 5.373-12 12v42.301C158.427 411.84 100.154 353.532 89.699 280H132c6.627 0 12-5.373 12-12v-24c0-6.627-5.373-12-12-12H89.699C100.16 158.427 158.468 100.154 232 89.699V132c0 6.627 5.373 12 12 12h24c6.627 0 12-5.373 12-12V89.699C353.573 100.16 411.846 158.468 422.301 232H380c-6.627 0-12 5.373-12 12v24c0 6.627 5.373 12 12 12h42.301C411.84 353.573 353.532 411.846 280 422.301zM288 256c0 17.673-14.327 32-32 32s-32-14.327-32-32c0-17.673 14.327-32 32-32s32 14.327 32 32z"/></svg>');
}
.autocomplete__result_type_history:before {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="%235B5B5D"><path d="M504 255.532c.252 136.64-111.182 248.372-247.822 248.468-64.014.045-122.373-24.163-166.394-63.942-5.097-4.606-5.3-12.543-.443-17.4l16.96-16.96c4.529-4.529 11.776-4.659 16.555-.395C158.208 436.843 204.848 456 256 456c110.549 0 200-89.468 200-200 0-110.549-89.468-200-200-200-55.52 0-105.708 22.574-141.923 59.043l49.091 48.413c7.641 7.535 2.305 20.544-8.426 20.544H26.412c-6.627 0-12-5.373-12-12V45.443c0-10.651 12.843-16.023 20.426-8.544l45.097 44.474C124.866 36.067 187.15 8 256 8c136.811 0 247.747 110.781 248 247.532zm-167.058 90.173l14.116-19.409c3.898-5.36 2.713-12.865-2.647-16.763L280 259.778V116c0-6.627-5.373-12-12-12h-24c-6.627 0-12 5.373-12 12v168.222l88.179 64.13c5.36 3.897 12.865 2.712 16.763-2.647z"/></svg>');
}
.autocomplete__result-name {
  font-weight: bold;
}
.autocomplete__result-type {
  font-size: 14px;
  color: #9c9c9c;
}
.autocomplete__terms {
  font-size: 14px;
  color: #9c9c9c;
  margin-left: 6px;
}
.autocomplete_mb_16 {
  margin-bottom: 16px;
}

.popup .autocomplete__input {
  border: none;
  height: 30px;
  padding: 0 0 4px;
  border-bottom: 1px dotted #5b5b5d;
  border-radius: 0;
  font-size: 20px;
  color: #f27135;
  width: 250px;
}
@media (min-width: 768px) {
  .popup .autocomplete__input {
    width: 100%;
  }
}

.textbox {
  width: 100%;
  height: 40px;
  padding: 0 12px;
  font-size: 16px;
  outline: 0;
  border: 1px solid #c3c3c4;
  border-radius: 8px;
}
.textbox_size_small {
  height: 32px;
  border-radius: 2px;
}
.textbox_type_placeholder {
  background-color: #e6e6e6;
}
.textbox.invalid {
  border-color: #ff5653;
  background-color: rgba(255, 86, 83, 0.1);
}
.textbox + .textbox, .textbox + .textarea, .textbox + .button {
  margin-top: 8px;
}

.popup .textbox {
  border: none;
  height: 30px;
  padding: 0 0 4px;
  border-bottom: 1px dotted #5b5b5d;
  border-radius: 0;
  font-size: 20px;
  color: #f27135;
  width: 250px;
  text-align: left;
}
@media (min-width: 768px) {
  .popup .textbox {
    width: 100%;
    text-align: left;
  }
}

.card {
  display: inline-block;
  position: relative;
  width: 100%;
  padding: 4px 4px 16px;
  border-radius: 12px;
  text-decoration: none;
  background: #fff;
  color: #5b5b5d;
  box-shadow: 1px 1px 3px #f1f1f1;
  font-size: 16px;
  overflow: hidden;
}
.card_type_additional {
  display: none;
}
.card_theme_transparent {
  box-shadow: none;
  background: transparent;
}
.card__visuals {
  position: relative;
}
.card__image {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #e6e6e6;
  background-position: center;
}
.card__image_ratio_universal {
  padding-bottom: 56.25%;
}
.card__image_ratio_square {
  padding-bottom: 100%;
}
.card__image_ratio_portrait {
  padding-bottom: 120%;
}
.card__image_shape_circle {
  border-radius: 100%;
}
.card__image_shape_rounded4 {
  border-radius: 4px;
}
.card__image_type_inline {
  display: block;
  width: auto;
  background-color: transparent;
  margin: 0 auto;
}
.card__image_bg_transparent {
  background-color: transparent;
}
.card__image-link {
  display: block;
  text-align: center;
  margin: 16px 0;
}
.card__icon {
  display: block;
  text-align: center;
}
.card__icon > svg {
  width: 80px;
  height: 80px;
}
.card__label {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 50%);
  padding: 6px 16px;
  border-radius: 20px;
  white-space: nowrap;
  background: #f27135;
  color: #fff;
}
.card__label_type_link {
  z-index: 2;
  text-decoration: none;
  background-color: #f27135;
}
.card__label_type_link:hover {
  background-color: #ea6100;
}
.card__label_type_link:active {
  background-color: #f28b5f;
}
.card__tags {
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  justify-content: right;
  padding-left: 32px;
}
.card__tag {
  background: #fff;
  font-size: 12px;
  padding: 4px;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1);
  text-align: center;
}
.card__chip {
  display: inline-block;
  background: #e6e6e6;
  text-decoration: none;
  padding: 2px 4px;
  border-radius: 2px;
}
.card__chip.link {
  text-decoration: none;
  cursor: pointer;
  position: relative;
  z-index: 1;
}
.card__content {
  position: relative;
  margin-top: 24px;
  padding: 0 8px;
}
.card__align-center {
  text-align: center;
}
.card__heading {
  display: block;
  font-size: 20px;
}
.card__more {
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 38px;
  text-align: right;
  background-color: #fff;
  font-size: 14px;
  padding-right: 6px;
  padding-bottom: 8px;
  padding-top: 6px;
}
.card__block-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.card__inline-link {
  position: relative;
  z-index: 2;
}
.card__link-container {
  text-align: center;
}
.card__image-link + .card__link-container {
  margin-top: -16px;
}
.card__link-container + .card__link-container {
  margin-top: 8px;
}
.card__link-container + .card__text {
  margin-top: 16px;
}
@media (min-width: 768px) {
  .card_type_additional {
    display: inline-block;
  }
}
@media (min-width: 1200px) {
  .card_type_additional {
    display: none;
  }
}

.cards {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -16px;
}
.cards__heading {
  display: block;
  flex: 0 0 100%;
  font-size: 20px;
  font-weight: bold;
  margin: 0 0 8px;
}
.cards__subheading {
  position: relative;
  display: block;
  flex: 0 0 100%;
  margin-bottom: 16px;
  color: #9c9c9c;
  min-height: 32px;
  line-height: 32px;
}
.cards__float-link {
  margin-top: 8px;
}
@media (min-width: 768px) {
  .cards__float-link {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.cards > .card {
  vertical-align: top;
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  .cards {
    margin-bottom: -32px;
  }
  .cards > .card {
    margin-bottom: 32px;
  }
  .cards_with_preadditional > .card:first-child {
    display: none;
  }
}
@media (min-width: 1200px) {
  .cards_with_preadditional > .card:first-child {
    display: block;
  }
}

.card__heading + .card__text,
.card__text + .card__text,
.card__text + .card__chips {
  margin-top: 8px;
}

@media (min-width: 768px) {
  .cards.cards_rows_3 > .card {
    width: calc(50% - 16px);
    margin-right: 32px;
  }
  .cards.cards_rows_3 > .card:nth-of-type(2n) {
    margin-right: 0;
  }
}
@media (min-width: 1200px) {
  .cards.cards_rows_3 > .card {
    width: calc(33.3333333333% - 21.3333333333px);
    margin-right: 32px;
  }
  .cards.cards_rows_3 > .card:nth-of-type(2n) {
    margin-right: 32px;
  }
  .cards.cards_rows_3 > .card:nth-of-type(3n) {
    margin-right: 0;
  }
}

@media (min-width: 768px) {
  .cards_rows_4 > .card {
    width: calc(50% - 16px);
    margin-right: 32px;
  }
  .cards_rows_4 > .card:nth-of-type(2n) {
    margin-right: 0;
  }
}
@media (min-width: 1200px) {
  .cards_rows_4 > .card {
    width: calc(25% - 16px);
    margin-right: 16px;
  }
  .cards_rows_4 > .card:nth-of-type(2n) {
    margin-right: 16px;
  }
  .cards_rows_4 > .card:nth-of-type(4n) {
    margin-right: 0;
  }
}

.property-card {
  padding: 0;
  border-radius: 6px;
}

.property-card__visuals,
.property-card__photos {
  position: relative;
}

.property-card__photo {
  position: relative;
  width: 100%;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
}
.property-card__photo > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.property-card__control {
  position: absolute;
  top: 0;
  width: 60px;
  height: 100%;
}
.property-card__control > svg {
  width: 12px;
  fill: #fff;
  filter: drop-shadow(0rem 0.125rem 0.1875rem rgba(0, 0, 0, 0.25));
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.property-card__control:hover > svg {
  fill: #f27135;
}

.property-card__control_type_prev {
  left: 0;
}
.property-card__control_type_prev > svg {
  left: 8px;
}

.property-card__control_type_next {
  right: 0;
}
.property-card__control_type_next > svg {
  right: 8px;
}

.property-card__date,
.property-card__state {
  font-size: 12px;
  color: #fff;
  padding: 2px 8px;
  border-radius: 10px;
}

.property-card__date {
  position: absolute;
  top: 8px;
  left: 8px;
  background-color: #545454;
}

.property-card__state {
  margin-left: 8px;
  background-color: #00779a;
}

.property-card__tour {
  position: absolute;
  top: 8px;
  right: 44px;
}
.property-card__tour > svg {
  width: 20px;
  fill: #fff;
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.5));
}

.property-card__favorite {
  right: 0;
  left: auto;
}
.property-card__favorite > svg {
  width: 20px;
}

.property-card__heading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.property-card__price {
  font-size: 24px;
  color: #ea6100;
}

.property-card__location {
  text-align: center;
  margin-bottom: 16px;
  font-weight: bold;
}
.property-card__location > svg {
  width: 10px;
  fill: currentColor;
}

.property-card__description {
  text-align: center;
}

.property-card__badges {
  margin-top: 16px;
  line-height: 24px;
  height: 24px;
  font-size: 14px;
  text-align: center;
}

.property-card__badge {
  display: inline-block;
  margin: 0 6px;
  vertical-align: top;
}
.property-card__badge-icon {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 24px;
}
.property-card__badge-icon > svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  fill: #5b5b5d;
}
.property-card__badge-value {
  display: inline-block;
  margin-left: 2px;
  vertical-align: top;
}

.property-card__details {
  padding: 16px;
}

.property-card_type_nofav .property-card__tour {
  right: 8px;
}

.article-card__content {
  height: 218px;
  overflow: hidden;
}
.article-card__visuals {
  margin: -4px -4px 0;
}
.article-card__heading {
  line-height: 22px;
}
.article-card__date.card__text {
  margin-top: 6px;
}
.article-card__summary.card__text {
  margin-top: 8px;
}
.article-card__image {
  position: relative;
  width: 100%;
  background-color: #fdfbf6;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
}
.article-card__image > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.area-card__content {
  height: 150px;
  overflow: hidden;
}

.area-card__visuals {
  margin: -4px -4px 0;
}

.tool-card {
  padding: 32px;
  min-height: 270px;
}
.tool-card__content {
  margin-top: 0;
  padding: 0;
}
.tool-card__heading {
  font-size: 24px;
}
.tool-card__button {
  text-align: center;
  margin-top: 24px;
}
.tool-card__powered {
  margin-top: 24px;
  font-size: 14px;
  text-align: center;
}

#nakedCard .button {
  background-color: #40CB7B;
}
#nakedCard .tool-card__powered-logo {
  width: 128px;
  margin: 0 auto;
}
#nakedCard .tool-card__powered-posttext {
  font-size: 12px;
  color: #adadad;
}

#movingCard .button {
  background-color: #000;
}
#movingCard .tool-card__powered-logo {
  width: 128px;
  margin: 8px auto 0;
}
#movingCard .card__icon > svg {
  width: 64px;
}

.agent-card_type_preview .agent-card__photo {
  width: 128px;
  padding-bottom: 128px;
  margin: 0 auto;
}
.agent-card_type_preview .agent-card__content {
  margin-top: 8px;
}
.agent-card_type_preview .agent-card__name {
  font-size: 18px;
}
.agent-card_type_preview .agent-card__area.card__text {
  margin-top: 0;
  color: #9c9c9c;
}

.link {
  display: inline;
  text-decoration: underline;
  cursor: pointer;
  color: #5b5b5d;
}
.link:hover {
  color: #ea6100;
}
.link:active {
  color: #f28b5f;
}
.link > svg {
  fill: #5b5b5d;
}
.link:hover > svg {
  fill: #ea6100;
}
.link:active > svg {
  fill: #f28b5f;
}
.link > svg {
  width: 14px;
  height: 14px;
  fill: #5b5b5d;
}
.link_type_action, .link_type_call, .link_type_accent {
  padding-bottom: 2px;
  border-bottom: 1px dotted #5b5b5d;
  text-decoration: none;
}
.link_type_call, .link_type_simple {
  color: #f27135;
}
.link_type_camo, .link_type_simple {
  text-decoration: none;
  border-bottom: none;
}
.link_type_camo {
  color: inherit;
}
.link_type_accent {
  color: #f27135;
  font-weight: bold;
}
.link_type_tough {
  white-space: nowrap;
}
.link_type_icon {
  display: inline-block;
  font-size: 0;
  text-decoration: none;
}
.link_type_icon > svg {
  width: 24px;
  height: 24px;
}
.link_type_icon + .link_type_icon {
  margin-left: 8px;
}

.dropdown {
  position: relative;
  display: inline-block;
  margin: 0;
}
.dropdown__options {
  position: absolute;
  display: none;
  width: 200px;
  max-height: 250px;
  left: 0;
  top: 0;
  z-index: 100;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  text-align: center;
  background: #fff;
  border: 1px solid #c3c3c4;
  border-radius: 2px;
}
.dropdown__options_state_opened {
  display: block;
}
.dropdown__options_render_outside {
  position: initial;
  top: 0;
  left: 0;
  transform: none;
  margin-top: 4px;
}
.dropdown__option {
  color: #f27135;
  font-size: 20px;
  line-height: 40px;
  cursor: pointer;
}
.dropdown__option:hover {
  background: #e6e6e6;
}
.dropdown__option_state_selected, .dropdown__option_state_selected:hover {
  background: #f27135;
  color: #fff;
}
.dropdown__value {
  display: inline-block;
  padding-bottom: 4px;
  border-bottom: 1px dotted #5b5b5d;
  font-size: 20px;
  color: #f27135;
  cursor: pointer;
}
.dropdown__label {
  font-size: 12px;
  color: #9c9c9c;
}
.dropdown_state_opened .dropdown__options {
  display: block;
}
@media (min-width: 768px) {
  .dropdown__options {
    width: 220px;
  }
}

.tether-dropdown-not-inited {
  left: -100%;
}

.tether-dropdown-element {
  z-index: 100;
}

.dropdown_type_classic .dropdown__value {
  position: relative;
  padding-right: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}
.dropdown_type_classic .dropdown__value:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 6px solid #9c9c9c;
  top: 9px;
  right: 4px;
  position: absolute;
}
.dropdown_type_classic .dropdown__value-text {
  display: inline-block;
  line-height: 20px;
  color: #9c9c9c;
  padding-bottom: 2px;
  border-bottom: 1px dotted #9c9c9c;
}
.dropdown_type_classic .dropdown__options {
  display: none;
  position: absolute;
  width: 110px;
  top: 26px;
  left: auto;
  right: 0;
  text-align: right;
  padding-right: 16px;
  padding-bottom: 0;
  background: transparent;
  border: none;
}
.dropdown_type_classic .dropdown__option {
  font-size: 16px;
  cursor: pointer;
  color: #9c9c9c;
  line-height: 20px;
}
.dropdown_type_classic .dropdown__option:hover {
  background: transparent;
}
.dropdown_type_classic .dropdown__option_state_selected {
  display: none;
}
.dropdown_type_classic.dropdown_state_opened .dropdown__options {
  display: block;
}
.dropdown_type_classic.dropdown_state_opened .dropdown__value:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 6px solid #9c9c9c;
  border-top: none;
}

.dropdown_type_hero .dropdown__value {
  font-size: 24px;
}
@media (min-width: 768px) {
  .dropdown_type_hero .dropdown__value {
    font-size: 32px;
  }
}

.checkbox-list {
  position: relative;
  display: inline-block;
  margin: 0;
}
.checkbox-list__text {
  color: #f27135;
  border-bottom: 1px dotted #5b5b5d;
  padding-bottom: 4px;
  cursor: pointer;
}
.checkbox-list__options {
  display: none;
  position: absolute;
  width: 280px;
  max-height: 250px;
  top: 0;
  left: 0;
  z-index: 10;
  overflow: auto;
  background: #fff;
  border: 1px solid #c3c3c4;
  padding: 12px 16px;
  border-radius: 2px;
  font-size: 16px;
  text-align: left;
}
.checkbox-list__options_state_opened {
  display: block;
}
.checkbox-list__options_render_outside {
  position: initial;
  top: 0;
  left: 0;
  transform: none;
  margin-top: 4px;
}
.checkbox-list__option {
  padding: 4px 0;
}
.checkbox-list__option_checked .checkbox-list__box, .checkbox-list__option_state_checked .checkbox-list__box {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill="%23F27135"><path d="M400 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zm0 400H48V80h352v352zm-35.864-241.724L191.547 361.48c-4.705 4.667-12.303 4.637-16.97-.068l-90.781-91.516c-4.667-4.705-4.637-12.303.069-16.971l22.719-22.536c4.705-4.667 12.303-4.637 16.97.069l59.792 60.277 141.352-140.216c4.705-4.667 12.303-4.637 16.97.068l22.536 22.718c4.667 4.706 4.637 12.304-.068 16.971z"/></svg>');
}
.checkbox-list__option_group {
  font-weight: bold;
  font-size: 18px;
}
.checkbox-list__option_group > .checkbox-list__box {
  display: none;
}
.checkbox-list__option + .checkbox-list__option_group {
  margin-top: 16px;
}
.checkbox-list__box {
  position: relative;
  top: 2px;
  margin-right: 8px;
  cursor: pointer;
  display: inline-block;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill="%235B5B5D"><path d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-6 400H54c-3.3 0-6-2.7-6-6V86c0-3.3 2.7-6 6-6h340c3.3 0 6 2.7 6 6v340c0 3.3-2.7 6-6 6z"/></svg>');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}
.checkbox-list__label {
  cursor: pointer;
  color: #5b5b5d;
}
.checkbox-list_opened .checkbox-list__options, .checkbox-list_state_opened .checkbox-list__options {
  display: block;
}

.popup__row .checkbox-list__text {
  font-size: 20px;
}

.checkbox-list {
  margin: 0 8px;
}

.counter {
  position: relative;
  height: 32px;
  line-height: 32px;
  text-align: right;
}
.counter__label {
  position: absolute;
  top: 0;
  left: 0;
}
.counter__value {
  display: inline-block;
  min-width: 40px;
  text-align: center;
}
.counter__control {
  display: inline-block;
  width: 32px;
  height: 32px;
  line-height: 32px;
  border: 1px solid #f27135;
  border-radius: 16px;
  text-align: center;
  cursor: pointer;
}
.counter__control:before {
  position: relative;
  top: -1px;
  left: 0;
}
.counter__control_type_dec:before {
  content: "-";
}
.counter__control_type_inc:before {
  content: "+";
}
.counter__control_state_disabled {
  border-color: #c3c3c4;
}
@media (min-width: 768px) {
  .counter {
    text-align: left;
  }
  .counter__label {
    position: initial;
    display: inline-block;
    min-width: 105px;
  }
}

.switcher {
  position: relative;
  height: 32px;
  line-height: 32px;
  text-align: right;
}
.switcher__label {
  position: absolute;
  top: 0;
  left: 0;
  vertical-align: top;
}
.switcher__control {
  position: relative;
  display: inline-block;
  width: 64px;
  height: 32px;
  line-height: 32px;
  border: 1px solid #d6d6d6;
  background: #dddddd;
  border-radius: 16px;
  text-align: center;
  cursor: pointer;
  transition: background-color ease 0.3s;
}
.switcher__control:before {
  content: " ";
  position: absolute;
  top: 1px;
  left: 2px;
  width: 28px;
  height: 28px;
  background: #fff;
  border-radius: 15px;
  transition: left ease 0.3s;
}
.switcher_state_checked > .switcher__control {
  background: #f27135;
}
.switcher_state_checked > .switcher__control:before {
  left: 32px;
}
@media (min-width: 768px) {
  .switcher {
    text-align: left;
  }
  .switcher__label {
    position: initial;
    display: inline-block;
    min-width: 105px;
  }
}

.range-dropdown {
  display: inline-block;
  position: relative;
}
.range-dropdown__caption {
  display: inline-block;
  padding-bottom: 4px;
  border-bottom: 1px dotted #5b5b5d;
  color: #f27135;
  cursor: pointer;
}
.range-dropdown__options {
  position: absolute;
  min-width: 200px;
  left: 0;
  top: 0;
  z-index: 100;
  padding: 8px 16px;
  text-align: left;
  background: #fff;
  border: 1px solid #c3c3c4;
  border-radius: 2px;
}
.range-dropdown__options_state_opened {
  display: block;
}
.range-dropdown__options_render_outside {
  position: initial;
  top: 0;
  left: 0;
  transform: none;
  margin-top: 4px;
}
@media (min-width: 768px) {
  .range-dropdown__options {
    justify-content: center;
  }
  .range-dropdown__options:not(.hidden) {
    display: flex;
  }
  .range-dropdown__splitter {
    display: inline-block;
    padding: 0 8px;
  }
}

.splitter {
  display: inline-block;
  padding: 0 4px;
  vertical-align: top;
}
.splitter_type_large {
  padding: 0 8px;
}

.menu {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 11;
  background: #fff;
  border-top: 1px solid #c3c3c4;
}
.menu svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.menu__bar {
  display: flex;
  width: 320px;
  height: 60px;
  margin: 0 auto;
  justify-content: center;
  flex-direction: row-reverse;
}
.menu__bar-item {
  flex: 0 0 80px;
  height: 60px;
  vertical-align: top;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  color: #5b5b5d;
}
.menu__bar-item_type_button {
  flex: 0 0 138px;
  margin-top: 10px;
}
.menu__bar-icon {
  position: relative;
  width: 18px;
  height: 28px;
  margin: 6px auto 0;
}
.menu__bar-icon > svg {
  width: 100%;
  fill: #5b5b5d;
}
.menu__popup {
  position: relative;
  display: none;
  padding: 72px 0;
  background: #f27135;
}
.menu__backdrop {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  visibility: hidden;
  opacity: 0;
}
.menu__close {
  position: absolute;
  top: 0;
  right: 0;
  width: 64px;
  height: 64px;
  z-index: 100;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
  background-color: tranparent;
}
.menu__close:hover {
  background-color: #fff;
}
.menu__close:active {
  background-color: #f5f5f5;
}
.menu__close > svg {
  fill: #fff;
}
.menu__close:hover > svg {
  fill: #ea6100;
}
.menu__close:active > svg {
  fill: #f28b5f;
}
.menu__close > svg {
  width: 14px;
}
.menu__links {
  display: block;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.menu__link {
  position: relative;
  display: block;
  line-height: 48px;
  text-decoration: none;
  cursor: pointer;
  font-size: 24px;
  padding: 0 24px;
  color: #fff;
}
.menu__link svg {
  fill: #fff;
}
.menu__link:hover {
  background: #fff;
}
.menu__link:hover svg {
  fill: #ea6100;
}
.menu__link:active {
  background: #fff;
}
.menu__link:active svg {
  fill: #f28b5f;
}
.menu__link_type_category > .menu__key_type_close {
  display: none;
}
.menu__link_type_sub {
  padding-left: 44px;
  font-size: 20px;
}
.menu__link_state_opened > .menu__key_type_close {
  display: block;
}
.menu__link_state_opened > .menu__key_type_open {
  display: none;
}
.menu__key {
  position: absolute;
  width: 64px;
  height: 48px;
  right: 0;
  top: 0;
}
.menu__key > svg {
  width: 24px;
  height: 24px;
}
.menu__category {
  height: 0;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}
.menu__category_state_opened {
  height: auto;
  max-height: 200px;
}
.menu__socials {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding-bottom: 16px;
  text-align: center;
}
.menu__social {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  vertical-align: top;
}
.menu__social + .menu__social {
  margin-left: 20px;
}
.menu__social > svg {
  fill: #fff;
}
.menu__social:hover > svg {
  fill: #ea6100;
}
.menu__social:active > svg {
  fill: #f28b5f;
}
.menu__social > svg {
  fill: #fff;
}
.menu__social:hover, .menu__social:active {
  border-radius: 20px;
  background: #fff;
}
.menu__social_type_facebook > svg {
  width: 17px;
}
.menu__social_type_twitter > svg {
  width: 28px;
}
.menu__social_type_instagram > svg {
  width: 25px;
}
.menu_state_active {
  display: block;
  position: fixed;
  border-top: none;
  height: 100%;
  opacity: 1;
  z-index: 100;
}
.menu_state_active .menu__bar {
  display: none;
}
.menu_state_active .menu__popup {
  display: block;
  height: 100%;
}
.menu_animation_in {
  animation-name: menuSlideInUp;
  animation-duration: 0.3s;
}
.menu_animation_in .menu__link {
  animation-name: menuSlideInLeft;
  animation-duration: 0.6s;
}
.menu_animation_in .menu__socials {
  animation-name: menuSlideInUpSocials;
  animation-duration: 0.5s;
}
.menu_animation_out {
  animation-name: menuSlideOutDown;
  animation-duration: 0.3s;
}
.menu_animation_in, .menu_animation_out {
  z-index: 100;
}
@media (min-width: 375px) {
  .menu__bar {
    width: 360px;
  }
  .menu__bar-item {
    flex: 0 0 90px;
  }
  .menu__bar-item_type_button {
    flex: 0 0 138px;
  }
}
@media (min-width: 425px) {
  .menu__bar {
    width: 420px;
  }
  .menu__bar-item {
    flex: 0 0 105px;
  }
  .menu__bar-item_type_button {
    flex: 0 0 138px;
  }
}
@media (min-width: 768px) {
  .menu {
    height: 100%;
    visibility: hidden;
    background: transparent;
  }
  .menu__bar {
    display: none;
  }
  .menu__popup {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    z-index: 100;
    transition: width ease 0.3s;
    padding: 88px 0;
  }
  .menu__link {
    padding: 0 32px;
  }
  .menu__link_type_sub {
    padding-left: 52px;
  }
  .menu_state_active {
    visibility: visible;
  }
  .menu_state_active .menu__popup {
    width: 400px;
  }
  .menu_state_active .menu__backdrop {
    transition: opacity ease 0.5s;
    visibility: visible;
    opacity: 1;
  }
  .menu_animation_in, .menu_animation_out {
    animation: none;
  }
}

@keyframes menuSlideInUp {
  0% {
    height: 60px;
    opacity: 0;
  }
  100% {
    height: 100%;
    opacity: 1;
  }
}
@keyframes menuSlideOutDown {
  0% {
    height: 100%;
  }
  100% {
    height: 60px;
  }
}
@keyframes menuSlideInUpSocials {
  0%, 30% {
    bottom: -30px;
    opacity: 0;
  }
  100% {
    bottom: 0;
    opacity: 1;
  }
}
@keyframes menuSlideInLeft {
  0%, 50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.header {
  display: flex;
  background-color: #f27135;
  height: 64px;
}
.header .link_with_icon > svg {
  fill: #fff;
}
.header__left-pane {
  position: relative;
  flex: 0 0 90px;
  background-color: #e85b1d;
}
.header__left-pane > .link_with_icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.header__left-pane > .link_with_icon > svg {
  position: absolute;
  right: 8px;
  top: 22px;
  width: 20px;
  height: 20px;
}
.header__center-pane {
  flex: 1 1 auto;
  padding: 6px 12px 0;
}
.header__right-pane {
  display: none;
  flex: 0 0 40px;
}
.header__right-pane > .link_with_icon {
  display: block;
  width: 100%;
  height: 100%;
  padding-top: 18px;
}
.header__right-pane > .link_with_icon > svg {
  width: 32px;
  height: 24px;
}
.header__menu {
  display: none;
}
.header__logo {
  display: inline-block;
  width: 54px;
  padding-top: 12px;
  padding-left: 16px;
  overflow: hidden;
}
.header__logo > svg {
  width: 228px;
}
.header__search {
  max-width: 300px;
}
@media (min-width: 425px) {
  .header__right-pane {
    display: block;
  }
}
@media (min-width: 768px) {
  .header__right-pane {
    flex-basis: 80px;
  }
  .header__right-pane > .link_with_icon {
    padding-left: 12px;
    padding-top: 14px;
  }
  .header__right-pane > .link_with_icon > svg {
    height: 32px;
  }
}
@media (min-width: 1024px) {
  .header__left-pane {
    flex-basis: auto;
  }
  .header__left-pane > .link_with_icon {
    display: none;
  }
  .header__center-pane {
    display: flex;
    padding-left: 24px;
  }
  .header__logo {
    width: auto;
    padding-right: 16px;
  }
  .header__search {
    flex: 0 1 300px;
    max-width: none;
  }
  .header__menu {
    display: block;
    flex: 1 0 auto;
    padding-left: 24px;
    padding-top: 10px;
    text-align: right;
  }
  .header__menu .link {
    display: inline-block;
    color: #fff;
    text-decoration: none;
    border-bottom: 3px solid transparent;
    font-size: 20px;
    font-weight: 300;
  }
  .header__menu .link:hover {
    border-bottom: 3px solid #fff;
  }
  .header__menu .link + .link {
    margin-left: 8px;
  }
}
@media (min-width: 1200px) {
  .header__logo {
    padding: 12px 24px 0;
  }
  .header__search {
    flex-basis: 400px;
  }
  .header__menu .link + .link {
    margin-left: 16px;
  }
}
@media (min-width: 1600px) {
  .header__menu .link + .link {
    margin-left: 24px;
  }
}

.header_type_transparent {
  background-color: transparent;
}
.header_type_transparent .header__left-pane {
  flex-basis: 280px;
  background-color: transparent;
}
.header_type_transparent .header__right-pane {
  display: block;
}
.header_type_transparent .header__center-pane {
  padding: 0;
}
.header_type_transparent .header__logo {
  width: auto;
}
.header_type_transparent .link_with_icon > svg {
  fill: #5b5b5d;
}
.header_type_transparent .link_with_icon:hover > svg {
  fill: #f27135;
}
.header_type_transparent .header__menu .link {
  color: #5b5b5d;
}
.header_type_transparent .header__menu .link:hover {
  color: #f27135;
  border-bottom-color: #f27135;
}
@media (min-width: 768px) {
  .header_type_transparent .header__right-pane > .link_with_icon {
    padding-left: 24px;
  }
}
@media (min-width: 1024px) {
  .header_type_transparent .header__center-pane {
    padding-top: 6px;
    padding-left: 24px;
  }
}

.footer {
  padding: 48px 0 92px;
  text-align: center;
  background: #e6e6e6;
  border-top: 1px solid #f2f2f2;
}
.footer__about {
  margin-bottom: 16px;
}
.footer__areas {
  font-size: large;
}
.footer__areas-text {
  margin-bottom: 12px;
}
@media (min-width: 768px) {
  .footer {
    padding-bottom: 32px;
  }
  .footer__about {
    line-height: 16px;
  }
}

.tabs__radio {
  display: none;
}

.tabs__radio ~ .tabs__content {
  display: none;
}

.tabs__radio.tabs__radio_index_0:checked ~ .tabs__content.tabs__content_index_0 {
  display: block;
}

.tabs__radio.tabs__radio_index_1:checked ~ .tabs__content.tabs__content_index_1 {
  display: block;
}

.tabs__radio.tabs__radio_index_2:checked ~ .tabs__content.tabs__content_index_2 {
  display: block;
}

.tabs__radio.tabs__radio_index_3:checked ~ .tabs__content.tabs__content_index_3 {
  display: block;
}

.tabs__radio.tabs__radio_index_4:checked ~ .tabs__content.tabs__content_index_4 {
  display: block;
}

.tabs__radio.tabs__radio_index_5:checked ~ .tabs__content.tabs__content_index_5 {
  display: block;
}

.tabs__radio.tabs__radio_index_6:checked ~ .tabs__content.tabs__content_index_6 {
  display: block;
}

.tabs__radio.tabs__radio_index_7:checked ~ .tabs__content.tabs__content_index_7 {
  display: block;
}

.tabs__radio.tabs__radio_index_8:checked ~ .tabs__content.tabs__content_index_8 {
  display: block;
}

.tabs__radio.tabs__radio_index_9:checked ~ .tabs__content.tabs__content_index_9 {
  display: block;
}

.tabs__radio.tabs__radio_index_10:checked ~ .tabs__content.tabs__content_index_10 {
  display: block;
}

.tabs__radio.tabs__radio_index_11:checked ~ .tabs__content.tabs__content_index_11 {
  display: block;
}

.tabs__radio.tabs__radio_index_12:checked ~ .tabs__content.tabs__content_index_12 {
  display: block;
}

.tabs__radio.tabs__radio_index_13:checked ~ .tabs__content.tabs__content_index_13 {
  display: block;
}

.tabs__selector {
  font-size: 18px;
  padding-bottom: 4px;
  color: #5b5b5d;
  border-bottom: 2px solid;
  margin: 0 8px;
  cursor: pointer;
  line-height: 36px;
}
.tabs__selector:hover {
  color: #f27135;
}

.tabs__radio:checked + .tabs__selector {
  color: #f27135;
  border-color: #f27135;
}

.search-bar__options {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: normal;
}
.search-bar__dropdown {
  display: inline-block;
  margin: 0 8px;
}
.search-bar__textbox {
  height: 48px;
  padding-right: 48px;
}
.search-bar__query {
  position: relative;
  max-width: 600px;
  margin: 0 auto;
}
.search-bar__operations, .search-bar__main-filters {
  display: none;
}
.search-bar__submit {
  position: absolute;
  top: 0;
  right: 0;
  width: 48px;
  height: 48px;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: none;
}
.search-bar__submit:before {
  content: "";
  position: absolute;
  width: 22px;
  height: 22px;
  top: 14px;
  left: 8px;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="%239C9C9C"><path d="M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z"/></svg>');
  background-size: cover;
}
.search-bar__submit:hover:before {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="%23F27135"><path d="M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z"/></svg>');
}
@media (min-width: 768px) {
  .search-bar__options {
    font-size: 32px;
  }
}

.search-bar__operations, .search-bar__main-filters {
  display: block;
}
.search-bar__operations {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}
.search-bar__operation {
  font-size: 20px;
  padding: 4px 16px;
  cursor: pointer;
  color: #5b5b5d;
  border-bottom: 2px solid;
}
.search-bar__operation:hover {
  color: #f27135;
}
@media (min-width: 768px) {
  .search-bar__operation {
    padding: 4px 32px;
  }
}
.search-bar__operation_type_dropdown .dropdown__value {
  color: #5b5b5d;
  border-bottom: none;
}
.search-bar__operation_state_selected {
  color: #f27135;
}
.search-bar__operation_state_selected .dropdown__value {
  color: #fff;
}
.search-bar__main-filters {
  text-align: center;
  margin-top: 24px;
}
.search-bar__main-filters .dropdown {
  display: block;
}
.search-bar__main-filters .search-bar__filter_type_more {
  position: relative;
  flex-basis: 85px;
  color: #f27135;
  cursor: pointer;
}
.search-bar__main-filters .search-bar__filter_type_more > span {
  display: inline-block;
  border-bottom: 1px dotted #5b5b5d;
  padding-bottom: 4px;
}
.search-bar__main-filters .search-bar__filter + .search-bar__filter {
  margin-top: 12px;
}
.search-bar__additional-filters {
  max-width: 600px;
  margin: 32px auto 0;
}
.search-bar__filter + .search-bar__filter, .search-bar__row + .search-bar__row {
  margin-top: 8px;
}
.search-bar__additional-filters .dropdown {
  display: block;
}
.search-bar__additional-filters .dropdown__value, .search-bar__additional-filters .dropdown__option, .search-bar__main-filters .dropdown__value, .search-bar__main-filters .dropdown__option {
  font-size: 16px;
}
.search-bar__row_align_right {
  text-align: right;
}
.search-bar__submit-button {
  position: relative;
  display: inline-block;
  padding: 12px 48px 12px 24px;
  background-color: #e6e6e6;
  border-radius: 25px;
  border: 1px solid #c3c3c4;
  font-weight: bold;
  cursor: pointer;
}
.search-bar__submit-button:hover {
  background-color: #f27135;
  color: #fff;
}
.search-bar__submit-icon {
  position: absolute;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  right: 12px;
  top: 9px;
  background-color: #f27135;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="%23FFFFFF"><path d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"/></svg>');
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: center;
}
.search-bar .checkbox-list {
  margin: 0;
}
.search-bar .checkbox-list, .search-bar__filter.dropdown,
.search-bar .range-dropdown {
  padding-right: 16px;
}
.search-bar .checkbox-list__text, .search-bar__filter > .dropdown__value,
.search-bar .range-dropdown__caption {
  position: relative;
}
.search-bar__filter_type_more {
  display: inline-block;
  padding-right: 16px;
}
.search-bar__filter_type_more > span {
  white-space: nowrap;
}
.search-bar .checkbox-list__text:after, .search-bar__filter > .dropdown__value:after,
.search-bar .range-dropdown__caption:after, .search-bar__filter_type_more:after {
  position: absolute;
  display: inline-block;
  content: "";
  top: 6px;
  right: -18px;
  width: 16px;
  height: 16px;
  background-size: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="%239C9C9C"><path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"/></svg>');
}
.search-bar__filter_type_more:after {
  right: -2px;
}
.search-bar_with_filters .search-bar__filter_type_more:after {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="%239C9C9C"><path d="M177 159.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 255.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 329.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1z"/></svg>');
}
.search-bar .autocomplete__input {
  height: 60px;
  border-radius: 30px;
  padding-right: 60px;
  padding-left: 32px;
  font-size: 18px;
  border: none;
  box-shadow: 0 15px 15px rgba(0, 0, 0, 0.06);
}
.search-bar__submit {
  width: 80px;
  height: 60px;
}
.search-bar__submit:before {
  top: 20px;
  left: 24px;
}
.search-bar .autocomplete_focus_true .autocomplete__input {
  border-radius: 30px 30px 0 0;
}
.search-bar .autocomplete__results {
  box-shadow: 0 15px 15px rgba(0, 0, 0, 0.06);
  border-radius: 0 0 30px 30px;
}
@media (min-width: 768px) {
  .search-bar__main-filters {
    display: flex;
    justify-content: center;
  }
  .search-bar__filter + .search-bar__filter, .search-bar__row + .search-bar__row {
    margin-top: 0;
  }
  .search-bar__main-filters .search-bar__filter + .search-bar__filter {
    margin-top: 0;
    margin-left: 24px;
  }
  .search-bar__operation + .search-bar__operation {
    margin-left: 24px;
  }
  .search-bar__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .search-bar__row + .search-bar__row {
    margin-top: 24px;
  }
  .search-bar__row_align_left {
    justify-content: left;
  }
  .search-bar__row_align_right {
    justify-content: right;
  }
  .search-bar__filter.counter {
    height: auto;
    text-align: center;
    flex-basis: 25%;
  }
  .search-bar__filter.counter > .counter__label {
    display: block;
  }
  .search-bar__filter.switcher {
    flex-basis: 33.3333333333%;
    cursor: pointer;
    height: 24px;
    line-height: 24px;
  }
  .search-bar__filter.switcher:nth-child(n+4) {
    margin-top: 8px;
  }
  .search-bar__filter.switcher .switcher__label {
    min-width: 0;
    margin-left: 8px;
  }
  .search-bar__filter.switcher .switcher__control {
    width: 24px;
    height: 24px;
    line-height: 24px;
    border-radius: 2px;
  }
  .search-bar__filter.switcher .switcher__control:before {
    content: none;
  }
  .search-bar__additional-filters .dropdown {
    flex-basis: 50%;
  }
  .search-bar__additional-filters .dropdown:nth-child(1) {
    text-align: right;
    padding-right: 16px;
  }
  .search-bar__additional-filters .dropdown:nth-child(1) .dropdown__options {
    left: auto;
    right: 0;
  }
  .search-bar__additional-filters .dropdown:nth-child(2) {
    padding-left: 16px;
  }
  .search-bar__additional-filters .search-bar__row:nth-child(3) {
    width: 450px;
    margin-left: 102px;
    justify-content: left;
  }
}

.search-submit {
  position: absolute;
  top: 0;
  right: 0;
  width: 48px;
  height: 48px;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: none;
}
.search-submit:before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="%239C9C9C"><path d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"/></svg>');
  background-size: cover;
}
.search-submit:hover:before {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="%23F27135"><path d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"/></svg>');
}

.dropdown__options {
  max-height: 325px;
}

.tabs__content {
  margin-top: 16px;
}

.search-bar {
  min-height: 140px;
}

.search-bar__textbox {
  height: 48px;
}

.agent-cards {
  justify-content: center;
}

.tabs {
  text-align: center;
}

.property-cards {
  padding-top: 32px;
}

.section__heading {
  text-align: center;
}

.section__heading + #search-bar {
  margin-top: 24px;
}

.property-cards {
  padding-top: 0;
}

.tool-cards + .section__text {
  padding-top: 32px;
}